import { useContext, useEffect, useState } from "react";
import axiosInstance from "../api";
import { useParams, useSearchParams } from "react-router-dom";
import VehicleInfo from "./VehicleInfo";
import { Spin, Tabs, Typography } from "antd";
import Maintenances from "../maintenance/Maintenances";
import BackTitle from "../common/BackTitle";
import TelepassTransactions from "./TelepassTransactions";
import VehicleLocation from "./VehicleLocation";
import { hasAnyRole } from "../util";
import "./VehicleInfo.css";
import Documents from "../document/Documents";
import { UserInfoContext } from "../common/FetchUserInfo";
import Charts from "./Charts";

const { Title } = Typography;

export default function VehicleDetails() {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [sp, setSP] = useSearchParams();
    const userInfo = useContext(UserInfoContext);

    useEffect(() => {
        if (loading) {
            axiosInstance
                .get(`vehicle/${id}`)
                .then(({ data }) => setData(data))
                .finally(() => setLoading(false));
        }
    }, [loading]);

    const items = [
        {
            key: "2",
            label: "Documenti",
            permission: 'document.read',
            children: <Documents />,
        },
        {
            key: "3",
            label: "Manutenzioni",
            permission: 'maintenance.read',
            children: <Maintenances onChange={() => setLoading(true)} vid={data.visualId} />,
        },
        {
            key: "4",
            label: "Telepass",
            permission: 'telepass.read',
            children: <TelepassTransactions />,
        },
        {
            key: "5",
            label: "Posizione",
            permission: 'vehicle.location_read',
            children: <VehicleLocation />,
        },
        {
            key: "6",
            label: "Parametri",
            permission: 'vehicle.read',
            children: <Charts/>
        }
    ].filter(x => userInfo.permissions?.includes(x.permission) ?? false);

    return (
        <Spin spinning={loading}>
            <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                <VehicleInfo data={data} />
                <Tabs
                    animated={false}
                    defaultActiveKey={sp.get("t")}
                    items={items}
                    onChange={(x) => {
                        [...sp.keys()].forEach((k) => sp.delete(k));
                        sp.set("t", x);
                        setSP(sp, { replace: true });
                    }}
                />
            </div>
        </Spin>
    );
}
