import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import axiosInstance from "../api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card } from "antd";
import dayjs from "dayjs";
import { Parser } from "expr-eval";
import { formatDateTime } from "../util";

const expSmoothing = (alpha, name, arr) =>
    arr.reduce(
        (acc, curr, index) =>
            index == 0
                ? [curr]
                : [
                      ...acc,
                      {
                          ...curr,
                          [name]: (alpha * curr[name] + (1 - alpha) * acc[index - 1][name]).toFixed(
                              2,
                          ),
                      },
                  ],
        [],
    );

const Chart = ({ chart }) => {
    const [data, setData] = useState([]);
    const { id } = useParams();
    useEffect(() => {
        if (chart)
            axiosInstance
                .get("deviceRecordAttribute", {
                    params: {
                        vehicle: id,
                        name: chart.attribute,
                        from: dayjs.utc().subtract(24, "hours").format("YYYY-MM-DDTHH:mm"),
                    },
                })
                .then(({ data }) =>
                    setData(
                        expSmoothing(
                            0.5,
                            "value",
                            data.map((x) => {
                                const numberValue = Number(x.value);
                                const res = !!chart.transformFn
                                    ? Parser.evaluate(chart.transformFn, { x: numberValue })
                                    : numberValue;
                                return {
                                    ...x,
                                    value: res,
                                    timestamp: formatDateTime(x.timestamp),
                                };
                            }),
                        ),
                    ),
                );
    }, [chart]);

    return (
        data.length > 0 && (
            <Card title={chart.name} bodyStyle={{ width: 500, height: 300 }} variant="outlined">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        data={data}
                        margin={{
                            top: 10,
                            left: 10,
                            bottom: 10,
                        }}
                    >
                        <XAxis dataKey="timestamp" />
                        <YAxis domain={[0,Math.max(...data.map(x=>x.value))]}/>
                        <Tooltip />
                        <Line type="linear" dataKey="value" stroke="#8884d8" dot={false} />
                    </LineChart>
                </ResponsiveContainer>
            </Card>
        )
    );
};

export default function Charts() {
    const [charts, setCharts] = useState([]);

    useEffect(() => {
        axiosInstance.get("chart").then(({ data }) => setCharts(data));
    }, []);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                columnGap: 8,
                flexWrap: "wrap",
                rowGap: 8,
            }}
        >
            {charts.map((x) => (
                <Chart chart={x} key={x.id} />
            ))}
        </div>
    );
}
